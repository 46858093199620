import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { wrapper } from 'lib/store';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import Error from 'next/error';
import getConfig from 'next/config';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import apolloCacheOptions from '../apollo-cache-options';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import svSE from 'antd/lib/locale/sv_SE';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import Cookies from 'js-cookie';
import config from 'config';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import useRbacSettingsHook from 'hooks/useRbacSettings';
import { refreshToken } from 'lib/util';
import getAuthorization from 'lib/getAuthorization';
import { FortnoxRedirectModal } from '../components/Invoicing/FortnoxRedirectModal';
import { QueryClientProvider, QueryClient } from 'react-query';
import { MantineProvider } from '@mantine/core';

// it should be require otherwise next-plugin-antd-less doesn't import global styles
require('../app.less');

const DEBUG = false;

let refreshingPromise = null;

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const customFetch = async (uri, opts) => {
	let options = { ...opts };
	options.headers.authorization = 'Bearer ' + getAuthorization()?.token;

	// If user impersonation is active, send a custom header on queries.
	const impersonateUserUUID = Cookies.get('impersonateUserUUID');
	if (impersonateUserUUID) {
		const { query } = JSON.parse(opts.body);
		DEBUG && console.log(query);
		if (query.indexOf('mutation') < 0) {
			options.headers['x-impersonate-user'] = impersonateUserUUID;
		}
	}
	DEBUG &&
		console.log(
			'customFetch',
			options.headers.authorization.substring(0, 20),
			options.body.substring(0, 40),
			impersonateUserUUID,
		);

	const initialRequest = await fetch(uri, options);

	if (initialRequest.ok) {
		return initialRequest;
	}
	const json = await initialRequest.json();

	DEBUG && console.log('APOLLO ERROR', json);

	// TODO PROP-38: test
	if (json && json.errors && json.errors.length === 1 && json.errors[0].message === 'jwt expired') {
		if (!refreshingPromise) {
			refreshingPromise = refreshToken();
		}

		return refreshingPromise.then((newAccessToken) => {
			refreshingPromise = null;
			options.headers.authorization = `Bearer ${newAccessToken}`;
			return fetch(uri, options);
		});
	} else {
		// Other error than JWT Expired, return something that looks like the original result.
		DEBUG && console.log('ERROR BODY', json);
		return Promise.resolve(new Response(JSON.stringify(json), {}));
	}
};

export const client = new ApolloClient({
	link: new HttpLink({
		uri: config[process.env.APP_ENVIRONMENT].graphql.endpoint,
		fetch: customFetch,
	}),
	cache: new InMemoryCache(apolloCacheOptions),
});

let versionAlert = 0;

function PropView({ Component, pageProps, isServer }) {
	const router = useRouter();
	const { getRoleAccess } = useRbacSettingsHook();
	const roleAccess = getRoleAccess();
	const dispatch = useDispatch();
	const firstRun = useRef(true);
	const theme = getConfig()?.publicRuntimeConfig?.theme;

	useEffect(() => {
		// Load account from cookie (unless we are logging in)
		if (router.pathname !== '/page/entrance') {
			let account;
			try {
				const storageAccount = localStorage.getItem('account');
				if (!account && storageAccount) account = JSON.parse(storageAccount);
			} catch (e) {
				console.log('Malformed account cookie. Resetting.', localStorage.getItem('account'));
				localStorage.removeItem('account');
			}

			if (account) {
				DEBUG && console.log('Redux ACCOUNT', account);
				dispatch({ type: 'ACCOUNT', payload: account });
			}
		}
	}, []);

	if (firstRun.current) {
		firstRun.current = false;

		dayjs.locale(router.locale);
		dispatch({ type: 'LANGUAGE', payload: router.locale });

		// Build version polling. Check if we need to reload to get latest version.
		if (typeof window !== 'undefined') {
			const buildId = window.__NEXT_DATA__.buildId;
			console.log('NextJS BUILD ID:', buildId);
			if (buildId !== 'development')
				setInterval(async () => {
					const sampleFile = window?.__BUILD_MANIFEST?.['/']?.[0];
					if (!sampleFile) return;
					const sampleUrl = window.location.origin + '/_next/static/' + buildId + '/_buildManifest.js';
					console.log('Checking', sampleUrl);
					try {
						const testResult = await fetch(sampleUrl, {
							method: 'HEAD',
							headers: {
								pragma: 'no-cache',
								'cache-control': 'no-cache',
							},
						});
						if (testResult.status === 404 && versionAlert === 0) {
							versionAlert++;
							alert('New Propview version is available. Please reload the application!');
							versionAlert--;
						}
					} catch (e) {
						// Stops network errors from generating error log
					}
				}, 5 * 60 * 1000);
		}

		if (router.pathname.includes('/admin/') && !roleAccess.loading) {
			if (
				!(
					roleAccess.properties_write ||
					roleAccess.properties_write_own ||
					roleAccess.premises_write ||
					roleAccess.premises_write_own ||
					roleAccess.properties_read
				)
			) {
				console.error('User does not have access to admin pages', { roleAccess });
				return isServer ? <Error statusCode={404} /> : null;
			}
		}
	}

	return (
		<>
			<Head>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<link rel="icon" type="image/png" href="/favicon.png" />

				<link
					rel="stylesheet"
					href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.4.1/mapbox-gl.css"
					type="text/css"
				/>
				<link
					rel="stylesheet"
					href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.4.2/mapbox-gl-geocoder.css"
					type="text/css"
				/>

				<script
					type="application/javascript"
					dangerouslySetInnerHTML={{
						__html: `
								(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
								0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
								for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
								MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
								mixpanel.init("${process.env.MIXPANEL_TOKEN}")
								`,
					}}
				/>
			</Head>

			<ApolloProvider client={client}>
				<QueryClientProvider client={new QueryClient()}>
					<ConfigProvider locale={router.locale === 'sv' ? svSE : enUS} componentSize="large">
						<FortnoxRedirectModal />
						<MantineProvider
							withGlobalStyles
							withNormalizeCSS
							theme={{
								colorScheme: 'light',
								colors: {
									brand: [
										'hsl(145, 95%, 55%)',
										'hsl(145, 95%, 50%)',
										'hsl(145, 95%, 45%)',
										'hsl(145, 95%, 30%)',
										'hsl(145, 95%, 35%)',
										'hsl(145, 95%, 30%)',
										'hsl(145, 95%, 25%)',
										'hsl(145, 95%, 20%)',
										'hsl(145, 95%, 15%)',
										'hsl(145, 95%, 10%)',
									],
								},
								primaryColor: 'brand',
							}}
						>
							<Component {...pageProps} />
						</MantineProvider>
					</ConfigProvider>
				</QueryClientProvider>
			</ApolloProvider>

			<style jsx global>
				{`
					#nprogress .bar {
						height: 4px;
						background: ${theme['@primary-color']};
					}
				`}
			</style>
		</>
	);
}

export default wrapper.withRedux(PropView);
