import jwtDecode from 'jwt-decode';

const DEBUG = false;

const getAuthorization = () => {
	if (typeof window === 'undefined') return '';

	const accountFromState = global.reduxStore?.getState()?.['account'];

	let accountFromUrl, accountFromCookie;
	const params = new URLSearchParams(window.location.search);
	const auth = params.get('auth');
	try {
		if (auth?.length > 0) accountFromUrl = JSON.parse(auth);
	} catch (e) {
		console.log('Auth parsing failed', e.message);
	}

	try {
		const c = localStorage.getItem('account');
		if (c?.length > 0) accountFromCookie = JSON.parse(c);
	} catch (e) {
		console.log('Account cookie parsing failed', e.message);
	}

	const account = accountFromState ?? accountFromUrl ?? accountFromCookie;

	if (!account) {
		console.log('getAuthorization(): No account available!', {
			auth,
			accountFromState,
			accountFromUrl,
			accountFromCookie,
		});
		location.replace('/');
		return '';
	}

	account.tokenPayload = jwtDecode(account.token);

	DEBUG && console.log('getAuthorization():', account?.token?.substring(0, 25));
	return account;
};

export default getAuthorization;

export { getAuthorization };
