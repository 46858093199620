import React, { useState } from 'react';
import { Modal, Alert } from 'antd';
import useText from 'hooks/useText';
import config from '../../config';
import { useDispatch, useSelector } from 'react-redux';

const useRedirectState = () => {
	const [isRedirected, setIsRedirected] = useState(false);

	return {
		status: isRedirected ? 'REDIRECTED' : 'NOT_REDIRECTED',
		setRedirected: () => setIsRedirected(true),
		setNotRedirected: () => setIsRedirected(false),
	};
};

export const FortnoxRedirectModal = () => {
	const dispatch = useDispatch();
	const isFortnoxModalOpen = useSelector((redux) => redux.ui.isFortnoxModalOpen);
	const vendorId = useSelector((redux) => redux.fortnox.vendor);
	const { getText } = useText();
	const { status, setNotRedirected, setRedirected } = useRedirectState();

	const handleRedirectToFortnoxLogin = () => {
		const url = `${config[process.env.APP_ENVIRONMENT].fortnox.auth}&state=${vendorId}`;
		window.open(url, '_blank').focus();
		setRedirected();
	};

	const handleCloseModal = () => {
		dispatch({ type: 'CLOSE_FORTNOX_MODAL' });
		setNotRedirected();
	};

	const okButtonText = status === 'NOT_REDIRECTED' ? getText('redirect') : getText('close');

	const handleModalOnOk = status === 'NOT_REDIRECTED' ? handleRedirectToFortnoxLogin : handleCloseModal;

	return (
		<Modal
			open={isFortnoxModalOpen}
			onCancel={handleCloseModal}
			onOk={handleModalOnOk}
			destroyOnClose
			okText={okButtonText}
		>
			{status === 'NOT_REDIRECTED' && (
				<Alert showIcon type="warning" message={getText('fornox-not-authorized-information')} />
			)}
			{status === 'REDIRECTED' && (
				<Alert showIcon type="success" message={getText('fornox-is-authorized-information')} />
			)}
		</Modal>
	);
};
