function offsetFromCursor(items, cursor, readField) {
	// Search from the back of the list because the cursor we're
	// looking for is typically the ID of the last item.
	for (let i = items.length - 1; i >= 0; --i) {
		const item = items[i];
		// Using readField works for both non-normalized objects
		// (returning item.id) and normalized references (returning
		// the id field from the referenced entity object), so it's
		// a good idea to use readField when you're not sure what
		// kind of elements you're dealing with.
		if (readField('nodeId', item) === cursor) {
			return i + 1;
		}
	}
	// The cursor could not be found.
	return -1;
}

const apolloCacheOptions = {
	typePolicies: {
		Query: {
			fields: {
				allProperties: {
					keyArgs: (args) => {
						const _args = { ...args };
						// The following line prevents separate caching if only the pagination cursor differ
						delete _args.after;

						const keyHash = JSON.stringify(_args);
						return keyHash;
					},
					merge(existing = { nodes: [] }, incoming, { args, readField }) {
						const { after: cursor } = args ?? {};
						const existingNodes = existing.nodes;
						const incomingNodes = incoming.nodes;

						const mergedNodes = existingNodes ? existingNodes.slice(0) : [];
						let offset = offsetFromCursor(mergedNodes, cursor, readField);

						// If the cursor wan't found, default to appending to the end of the list
						if (offset < 0) offset = mergedNodes.length;

						if (incoming.pageInfo?.hasPreviousPage === false) offset = 0;

						for (let i = 0; i < incomingNodes.length; ++i) {
							mergedNodes[offset + i] = incomingNodes[i];
						}

						return {
							...incoming,
							nodes: mergedNodes,
						};
					},
				},
			},
		},
	},
};

export default apolloCacheOptions;
