import { applyMiddleware, createStore } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

const initialState = {
	account: null,
	person: null,
	articles: [],
	resources: [],
	helpAndSupport: [],
	displayOnboarding: false,
	rbac: {},
	vendors: {},
	menuIsOpen: false,
	openSubMenu: [],
	texts: {},
	propertyCount: {},
	tags: [],
	enums: {},
	scriveCredentials: false,
	tenantPropertyAndPremisesCount: 0,
	ui: { isFortnoxModalOpen: false },
	fortnox: { vendor: null },
	bookkeepingAccounts: [],
};

const bindMiddleware = (middleware) => {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case HYDRATE:
			return {
				...state,
				texts: action.payload.texts,
				features: action.payload.features,
				articles: action.payload.articles,
				values: action.payload.values,
				tags: action.payload.tags,
				vendors: action.payload.vendors,
				rbac: action.payload.rbac,
				enums: action.payload.enums,
				helpAndSupport: action.payload.helpAndSupport,
				resources: action.payload.resources,
				bookkeepingAccounts: action.payload.bookkeepingAccounts,
			};
		case 'ACCOUNT':
			return { ...state, account: action.payload };
		case 'UPDATE_TOKEN':
			return { ...state, account: { ...state.account, token: action.payload } };
		case 'HELPANDSUPPORT':
			return { ...state, helpAndSupport: action.payload };
		case 'MENU_IS_OPEN':
			return { ...state, menuIsOpen: action.payload };
		case 'OPEN_SUB_MENU':
			return { ...state, openSubMenu: action.payload };
		case 'ENUMS':
			return { ...state, enums: action.payload };
		case 'VENDORS':
			return { ...state, vendors: action.payload };
		case 'RBAC':
			return { ...state, rbac: action.payload };
		case 'TEXTS':
			return { ...state, texts: action.payload };
		case 'FEATURES':
			return { ...state, features: action.payload };
		case 'TAGS':
			return { ...state, tags: action.payload };
		case 'ARTICLES':
			return { ...state, articles: action.payload };
		case 'RESOURCES':
			return { ...state, resources: action.payload };
		case 'VALUES':
			return { ...state, values: action.payload };
		case 'LANGUAGE':
			return { ...state, language: action.payload };
		case 'PROPERTYCOUNT':
			return { ...state, propertyCount: action.payload };
		case 'PERSON':
			return { ...state, person: action.payload };
		case 'DISPLAYONBOARDING':
			return { ...state, display: action.payload, person: { settings: { onboardingDone: true } } };
		case 'SCRIVE_CREDENTIALS':
			return { ...state, scriveCredentials: action.payload };
		case 'BOOKKEEPINGACCOUNTS':
			return { ...state, bookkeepingAccounts: action.payload };
		case 'TENANT_PROPERTY_AND_PREMISES_COUNT':
			return { ...state, tenantPropertyAndPremisesCount: action.payload };
		case 'OPEN_FORTNOX_MODAL':
			return {
				...state,
				ui: { ...state.ui, isFortnoxModalOpen: true },
				fortnox: { ...state.fortnox, vendor: action.payload },
			};
		case 'CLOSE_FORTNOX_MODAL':
			return {
				...state,
				ui: { ...state.ui, isFortnoxModalOpen: false },
				fortnox: { ...state.fortnox, vendor: null },
			};
		default:
			return state;
	}
};

export const store = createStore(reducer, bindMiddleware([]));

const initStore = () => {
	global.reduxStore = store;
	return global.reduxStore;
};

export const wrapper = createWrapper(initStore, { debug: false });
